// sizes
.badge-200 {
  width: 326px;
  height: 200px;
  background-size: 326px 200px;
  @include media-breakpoint-down(md) {
    width: 163px;
    height: 100px;
    background-size: 163px 100px;
  }
  @include media-breakpoint-down(sm) {
    width: 122px;
    height: 75px;
    background-size: 122px 75px;
  }
}
.badge-150 {
  width: 244px;
  height: 150px;
  background-size: 244px 150px;
}
.badge-124 {
  width: 202px;
  height: 124px;
  background-size: 202px 124px;
}
.badge-100 {
  width: 163px;
  height: 100px;
  background-size: 163px 100px;
}
.badge-80 {
  width: 130px;
  height: 80px;
  background-size: 130px 80px;
}
.badge-74 {
  width: 120px;
  height: 74px;
  background-size: 120px 74px;
}
.badge-50 {
  width: 81px;
  height: 50px;
  background-size: 81px 50px;
}

.infopoint-badge-h200 {
  @extend .badge-200;
  background-image: url("../../images/badges/info-point-badge-h200.png");
}

.meeting-area-badge-h200 {
  @extend .badge-200;
  background-image: url("../../images/badges/meeting-area-badge-h200.png");
}

.cafe-badge-h200 {
  @extend .badge-200;
  background-image: url("../../images/badges/cafe-badge-h200.png");
}

.event-hall-badge-h200 {
  @extend .badge-200;
  background-image: url("../../images/badges/event-hall-badge-h200.png");
}

.showroom-badge-h200 {
  @extend .badge-200;
  background-image: url("../../images/badges/showroom-badge-h200.png"); 
}

.expoarea-badge-h200 {
  @extend .badge-200;
  background-image: url("../../images/badges/expoarea-badge-h200.png");
}

.arthall-badge-h200 {
  @extend .badge-200;
  background-image: url("../../images/badges/arthall-badge-h200.png");
}

.kiosk-badge-h200 {
  @extend .badge-200;
  background-image: url("../../images/badges/kiosk-badge-h200.png");
}

.kiosk-badge-h400 {
  width: 177px;
  height: 400px;
  background-size: 177px 400px;
  background-image: url("../../images/kiosk.png");
  @include media-breakpoint-down(md) {
    width: 66px;
    height: 150px;
    background-size: 66px 150px;
  }
  @include media-breakpoint-down(sm) {
    width: 44px;
    height: 100px;
    background-size: 44px 100px;
  }
}