.video-modal {
  &.modal-body {
    position: relative;
    padding: 0px;
  }
  .btn-close {
    position: absolute;
    right: 10px;
    top: 5px;
    z-index: 999;
    font-size: 1.25rem;
    font-weight: normal;
    color: #fff;
    opacity: 1;
    @include media-breakpoint-up(md) {
      right: -30px;
      top: 0;
    }
  }
  #player {
    border-radius: $border-radius-lg;
  }
}

modal-container {
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-md .modal-header {
  background-image: url("../../images/wave-sm.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0px 0px;
  @include media-breakpoint-up(xl) {
    background-position: 0px -20px;
  }
}

.back-button {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 10;
}
.modal-content {
  background-color: $primary;
  &.opacity-9 {
    background-color: rgba($primary, .9);
  }
}

.modal-info-column {
  background-image: url("../../images/logos/expo-logo-bg-dark.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0 -2rem;
}
.modal-xl {
  .modal-header {
    padding-top: 2rem;
    padding-bottom: 4rem;
    padding-left: 2rem;
    min-height: 9rem;
    background-image: url("../../images/wave-xl.svg");
    background-size: 101%;
    background-repeat: no-repeat;
    background-position: 0px 0px;
    @include media-breakpoint-up(xl) {
      background-position: 0px -20px;
    }
    h1 {
      margin-top: 1rem;
      margin-right: 17%;
    }
    @include media-breakpoint-down(md) {
      h1 {
        margin-right:0;
      }
    }
  }

  @include media-breakpoint-only(md) {
    max-width: 600px;
  }
}

.modal-lg {
  .modal-header {
    background-image: url("../../images/wave-xl.svg");
    background-size: 101%;
    background-repeat: no-repeat;
    background-position: 0px 0px;
    h1 {
      margin-right: 250px;
    }
  }

  @include media-breakpoint-only(md) {
    max-width: 600px;
  }
}

.modal-fullscreen {
  background-color: #00000099;
  max-width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  @include media-breakpoint-up(lg) {
    .modal-content {
      max-width: 800px;
    }
  }
  .modal-content {
    margin: auto;
  }
}

.static-modal-wrapper {
  .modal {
    min-height: 850px;
  }
  @include media-breakpoint-between(lg, xxl) {
    .modal-xl {
      max-width: calc(100% - 150px);
      margin-left: 0px;
    }
  }
}

// .modal-header {
//   .btn-close,
//   .btn-close:hover {
//     padding: 0rem 1.4rem;
//     font-size: 2rem;
//     color: #fff;
//     text-shadow: none;
//     font-weight: 300;
//     opacity: 1;
//     &:focus {
//       outline: none;
//     }
//   }
// }

.category-list {
  li {
    a {
      color: #0a0a0a;
      &:hover {
        text-decoration: none;
      }
    }
    .btn-close {
      display: none;
      &:focus {
        outline: none;
      }
    }
    &.active {
      a {
        color: #fff;
      }
      .btn-close {
        display: block;
        color: #fff !important;
        opacity: 1;
        text-shadow: none;
        font-weight: 100;
      }
    }
  }
}
.input-group {
  .btn-close,
  .btn-close:hover,
  .btn-close:focus {
    opacity: 1 !important;
    color: #0a0a0a;
    padding-right: 0.5rem;
    background-color: #fff;
    outline: none;
  }
}

.place-modal {
  &.modal-xxl {
    max-width: 100%;
    width: 100vw;
    max-height: 100%;
    height: 100vh;
    margin: 0;
    border: none;
    border-radius: 0px;
    .modal-header {
      background: none;
    }
    .modal-content {
      border: none;
      border-radius: 0px;
      height: 100%;
      background-color: $primary;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      @include media-breakpoint-up(xl) {
        background-size: cover;
      }
    }
  }
}

.meetings {
  .modal-content {
    background-color: transparent;
    .modal-body {
      overflow-x: hidden;
    }
  }
}

.coffee {
  .modal-header {
    background-image: url("../../images/modal-sm-header-bg.svg");
    background-position: 0 -23px;
    background-color: rgba(0, 0, 0, 0.9);
  }
  .modal-content {
    background-color: transparent;
    border: none;
    border-radius: 0px;
  }
  .modal-body {
    background-color: rgba(0, 0, 0, 0.9);
  }
  @include media-breakpoint-up(md) {
    .modal-dialog-scrollable {
      height: subtract(100%, $modal-dialog-margin * 2);

      .modal-content {
        max-height: 100%;
        overflow: hidden;
      }

      .modal-body {
        overflow-y: auto;
      }
    }
  }
}
.arthall {
  .modal-content {
    background-color: rgba(0, 0, 0, 0.9);
  }
}

.eventhall {
  .drag-scroll-content {
    display: flex !important;
  }
  drag-scroll {
    @include media-breakpoint-up(lg) {
      width: 99%;
    }
    @include media-breakpoint-only(md) {
      width: calc(100vw - 179px);
    }
    @include media-breakpoint-down(sm) {
      width: calc(100vw - 27px);
    }
  }
}

.kiosk {
  drag-scroll {
    @include media-breakpoint-only(lg) {
      width: 85%;
    }
    @include media-breakpoint-only(md) {
      width: calc(100vw - 171px);
    }
    @include media-breakpoint-down(sm) {
      width: calc(100vw - 19px);
    }
  }
}
