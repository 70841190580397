//sizes

.icon-200 {
  width: 200px;
  height: 200px;
  background-size: 200px 200px;
}
.icon-150 {
  width: 150px;
  height: 150px;
  background-size: 150px 150px;
}
.icon-100 {
  width: 100px;
  height: 100px;
  background-size: 100px 100px;
  @include media-breakpoint-down(md) {
    width: 70px;
    height: 70px;
    background-size: 70px 70px;
  }
  @include media-breakpoint-down(sm) {
    width: 50px;
    height: 50px;
    background-size: 50px 50px;
  }
}
.icon-75 {
  width: 75px;
  height: 75px;
  background-size: 75px 75px;
  @include media-breakpoint-down(md) {
    width: 55px;
    height: 55px;
    background-size: 55px 55px;
  }
  @include media-breakpoint-down(sm) {
    width: 45px;
    height: 45px;
    background-size: 45px 45px;
  }
}
.icon-60 {
  width: 60px;
  height: 60px;
  background-size: 60px 60px;
  @include media-breakpoint-down(md) {
    width: 47px;
    height: 47px;
    background-size: 47px 47px;
  }
  @include media-breakpoint-down(sm) {
    width: 40px;
    height: 40px;
    background-size: 40px 40px;
  }
}
.icon-50 {
  width: 50px;
  height: 50px;
  background-size: 50px 50px;
  @include media-breakpoint-down(md) {
    width: 35px;
    height: 35px;
    background-size: 35px 35px;
  }
  @include media-breakpoint-down(sm) {
    width: 25px;
    height: 25px;
    background-size: 25px 25px;
  }
}

.video-icon {
  @extend .icon-100;
  background-image: url("../../images/icons/Video.png");
}
.video-icon-75 {
  @extend .icon-75;
  background-image: url("../../images/icons/Video.png");
}
.video-icon-50 {
  @extend .icon-50;
  background-image: url("../../images/icons/Video.png");
}
.exit-icon-100 {
  @extend .icon-100;
  background-image: url("../../images/icons/exit.png");
}
.enter-icon-100 {
  @extend .icon-100;
  background-image: url("../../images/icons/enter.png");
}
.enter-icon-75 {
  @extend .icon-75;
  background-image: url("../../images/icons/enter.png");
}
.enter-icon-50 {
  @extend .icon-50;
  background-image: url("../../images/icons/enter.png");
}

// icons
// info point

.infopoint-icon-100 {
  @extend .icon-100;
  background-image: url("../../images/icons/infoPoint.png");
}
.infopoint-icon-75 {
  @extend .icon-75;
  background-image: url("../../images/icons/infoPoint.png");
}
.infopoint-icon-60 {
  @extend .icon-60;
  background-image: url("../../images/icons/infoPoint.png");
}
.infopoint-icon-50 {
  @extend .icon-50;
  background-image: url("../../images/icons/infoPoint.png");
}
// meeting area

.meeting-area-icon-100 {
  @extend .icon-100;
  background-image: url("../../images/icons/meetingArea.png");
}
.meeting-area-icon-75 {
  @extend .icon-75;
  background-image: url("../../images/icons/meetingArea.png");
}
.meeting-area-icon-50 {
  @extend .icon-50;
  background-image: url("../../images/icons/meetingArea.png");
}
// cafe

.cafe-icon-100 {
  @extend .icon-100;
  background-image: url("../../images/icons/coffee.png");
}
.cafe-icon-75 {
  @extend .icon-75;
  background-image: url("../../images/icons/coffee.png");
}
.cafe-icon-50 {
  @extend .icon-50;
  background-image: url("../../images/icons/coffee.png");
}
// event hall

.event-hall-icon-100 {
  @extend .icon-100;
  background-image: url("../../images/icons/eventHall.png");
}
.event-hall-icon-75 {
  @extend .icon-75;
  background-image: url("../../images/icons/eventHall.png");
}
.event-hall-icon-50 {
  @extend .icon-50;
  background-image: url("../../images/icons/eventHall.png");
}
// showroom

.showroom-icon-100 {
  @extend .icon-100;
  background-image: url("../../images/icons/showroom.png");
}
.showroom-icon-75 {
  @extend .icon-75;
  background-image: url("../../images/icons/showroom.png");
}
.showroom-icon-50 {
  @extend .icon-50;
  background-image: url("../../images/icons/showroom.png");
}
// expo area

.expoarea-icon-100 {
  @extend .icon-100;
  background-image: url("../../images/icons/expoArea.png");
}
.expoarea-icon-75 {
  @extend .icon-75;
  background-image: url("../../images/icons/expoArea.png");
}
.expoarea-icon-50 {
  @extend .icon-50;
  background-image: url("../../images/icons/expoArea.png");
}
// kiosk icon

.kiosk-icon-100 {
  @extend .icon-100;
  background-image: url("../../images/icons/kiosk-icon.png");
}
.kiosk-icon-75 {
  @extend .icon-75;
  background-image: url("/../../images/icons/kiosk-icon.png");
}
.kiosk-icon-50 {
  @extend .icon-50;
  background-image: url("../../images/icons/kiosk-icon.png");
}

// arthall icon

.arthall-icon-100 {
  @extend .icon-100;
  background-image: url("../../images/icons/arthall.svg");
}
.arthall-icon-75 {
  @extend .icon-75;
  background-image: url("../../images/icons/arthall.svg");
}
.arthall-icon-50 {
  @extend .icon-50;
  background-image: url("../../images/icons/arthall.svg");
}

//stand icons

.client-back-icon {
  background-image: url("../../images/icons/client-back.png");
}
 