/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&family=Roboto:wght@400;500&display=swap");
@import "assets/css/scss/vairables";
@import "bootstrap";

@import "assets/css/scss/modal";
@import "assets/css/scss/icons";
@import "assets/css/scss/badges";
@import "assets/css/scss/mobile";

.rounded-bottom-right {
  border-bottom-right-radius: $border-radius-lg;
}

.max-vh-50 {
  max-height: 50vh;
}
.max-vh-55 {
  max-height: 55vh;
}
.max-vh-60 {
  max-height: 60vh;
}
.max-vh-75 {
  max-height: 75vh;
}

.mw-75 {
  max-width: 75%;
}

.popover {
  background-color: $primary;
  .arrow:after {
    border-top-color: $primary;
  }
}

//cistom scroll
.custom-scroll {
  &::-webkit-scrollbar {
    width: 0.5em;
    border-radius: 1rem;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 1rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 1rem;
  }
}

//autofill backgorund
/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
}

//colors
.bg-black {
  background-color: rgba(0, 0, 0, 1) !important;
}

//buttons
.btn-info,
.btn-secondary {
  color: #fff;
}

//pages bg
#bg {
  position: fixed;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
}
#bg img {
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  min-width: 50%;
  min-height: 50%;
}
// #bg {
//   img {
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//     position: fixed;
//   }
// }

//dragscroll
