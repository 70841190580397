app-navigation {
    .mobile {
        position: absolute;
        top: 0;
        width: 100%;
        left: 0;
    }
}
@include media-breakpoint-down(lg) {
    body {
        padding-top: 150px;
    }
}