$enable-negative-margins: true;

// $border-radius: 1.3rem;
$border-radius-lg: 1.3rem;
$font-family-sans-serif: "Roboto", sans-serif;
$enable-responsive-font-sizes: true;
$font-weight-bold: 500;
$font-weight-bolder: 700;
$headings-font-family: "Roboto Condensed", sans-serif;
$headings-font-weight: 400;
$font-size-lg: 1.5rem;
$modal-md: 435px;

$primary: #009a9a;
$secondary: #ff7300;
$info: #ff006a;

$dark: #007f7f;

//navbar
$navbar-dark-color: #fff;

//buttons
$input-btn-padding-y-lg: 0.75rem;
$input-btn-padding-x-lg: 2rem;
$input-btn-font-size-lg: $font-size-lg;
$btn-border-radius-lg: $border-radius-lg;
$btn-padding-x: 1rem;


//inputs
$custom-control-indicator-checked-bg: $secondary;
$input-placeholder-color: #000000;
$input-focus-box-shadow: none;
$form-select-focus-box-shadow: none;

//floating inputs
$form-floating-height:            add(2.5rem, 0px);
$form-floating-padding-x:         .5rem;
$form-floating-padding-y:         .5rem;
$form-floating-input-padding-t:   1.25rem;
$form-floating-input-padding-b:   .25rem;
$form-floating-label-opacity:     .65;
$form-floating-label-transform:   scale(.85) translateY(-.5rem) translateX(.15rem);
$form-floating-transition:        opacity .1s ease-in-out, transform .1s ease-in-out;

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 4),
    6: ($spacer * 4.5),
    7: ($spacer * 5)
  ),
  $spacers
);

$aspect-ratios: (
  "1x1": 100%,
  "4x3": calc(3 / 4 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "15x10": calc(10 / 15 * 100%),
  "21x9": calc(9 / 21 * 100%)
);